import React from 'react';
import { Anchor } from './FooterLink.styles';

const FooterLink = ({ children, ...rest }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <Anchor target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </Anchor>
  );
};

export default FooterLink;
