import React from 'react';

import DisneyLogo from 'components/publicWebsiteV2/common/Footer/components/DisneyLogo';

import {
  DISNEY_LIGHT_COLOR,
  DISNEY_DARK_COLOR,
  StyledZolaLogo,
  ZolaSignature,
} from 'components/publicWebsiteV2/common/Footer/Footer.styles';
import {
  DisneyLogoContainer,
  DisneyCopy,
  StyledFooterLink,
  ZolaCopyText,
  DisneyThemeCopyrightRow,
  DisneyThemeLinkRow,
} from './DisneyFooterContent.styles';

const DisneyFooterContent = ({ isDarkMode }: { isDarkMode?: boolean }): JSX.Element => (
  <>
    <StyledZolaLogo type="logomark" color={isDarkMode ? DISNEY_LIGHT_COLOR : DISNEY_DARK_COLOR} />
    <ZolaSignature>For all the days along the way</ZolaSignature>
    <DisneyThemeCopyrightRow>
      <ZolaCopyText>&copy; {new Date().getFullYear()} Zola, Inc. All rights reserved.</ZolaCopyText>
      <DisneyCopy>&copy;Disney</DisneyCopy>
    </DisneyThemeCopyrightRow>
    <DisneyThemeLinkRow>
      <StyledFooterLink href="https://help.zola.com/hc/categories/115000284811-For-Wedding-Guests">
        Guest FAQs
      </StyledFooterLink>
      <StyledFooterLink href="/privacy">Privacy</StyledFooterLink>
      <StyledFooterLink href="/terms">Terms</StyledFooterLink>
      <StyledFooterLink href="/webaccessibility">Accessibility</StyledFooterLink>
      <StyledFooterLink href="/privacy#CA-privacy-rights">CA Privacy Rights</StyledFooterLink>
    </DisneyThemeLinkRow>
    <DisneyLogoContainer isDarkMode={isDarkMode}>
      <DisneyLogo />
    </DisneyLogoContainer>
  </>
);
export default DisneyFooterContent;
