import styled from '@emotion/styled';

import FooterLink from 'components/publicWebsiteV2/common/Footer/components/FooterLink';

import {
  DISNEY_LIGHT_COLOR,
  DISNEY_DARK_COLOR,
  Row,
} from 'components/publicWebsiteV2/common/Footer/Footer.styles';
import { FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const ZolaCopyText = styled.span`
  ${FONT.textSizeUltraCompact(FONT.SIZE.EXTRA_SMALL)}
  font-weight: ${FONT.WEIGHT.BOLD};
`;

export const DisneyCopy = styled.span`
  font-family: 'Work Sans', sans;
  font-size: 13px;
  font-style: normal;
  font-weight: ${FONT.WEIGHT.REGULAR};
  line-height: 15px;
`;

export const DisneyLogoContainer = styled.div<{ isDarkMode?: boolean }>`
  margin-top: ${SPACING.MD};

  svg {
    ${({ isDarkMode }) => `
    color: ${isDarkMode ? DISNEY_LIGHT_COLOR : DISNEY_DARK_COLOR};
  `}
  }
`;

export const StyledFooterLink = styled(FooterLink)`
  ${FONT.textSizeUltraCompact(FONT.SIZE.EXTRA_SMALL)}
  font-weight: ${FONT.WEIGHT.BOLD};
  text-decoration: underline;
`;

// Smaller spacing for copyright row to maintain same footer height on all mobile screen sizes
export const DisneyThemeCopyrightRow = styled(Row)`
  > * {
    ${props => props.theme.MEDIA_QUERY.MOBILE} {
      padding: ${props => `${props.theme.SPACING.SM} ${props.theme.SPACING.XXS}`};
    }
  }
`;
// Constrained width to maintain same footer height on all mobile screen sizes
export const DisneyThemeLinkRow = styled(Row)`
  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    max-width: 380px;
  }
`;
