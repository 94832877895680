import React from 'react';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import FooterContent from 'components/publicWebsiteV2/common/Footer/components/FooterContent';
import DisneyFooterContent from 'components/publicWebsiteV2/common/Footer/components/DisneyFooterContent';

import publicWebsiteIsDisney from 'components/publicWebsiteV2/util/publicWebsiteIsDisney';

import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import { Container } from './Footer.styles';

/**
 *
 * @param param0 showDarkMode override; default is based on content
 * @returns
 */
const Footer = ({ showDarkMode }: { showDarkMode?: boolean }): JSX.Element => {
  const {
    state: {
      components: { backgroundColors },
      wedding,
    },
  } = useWebsiteThemeContext();

  const isDarkMode = showDarkMode || isDarkColor(backgroundColors.content);
  const isDisneyFooter = publicWebsiteIsDisney(wedding?.public_theme_v2?.motif_keys);

  return (
    <Container isDarkMode={isDarkMode}>
      {isDisneyFooter ? (
        <DisneyFooterContent isDarkMode={isDarkMode} />
      ) : (
        <FooterContent isDarkMode={isDarkMode} />
      )}
    </Container>
  );
};

export default Footer;
