import styled from '@emotion/styled';

import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';

export const DISNEY_LIGHT_COLOR = '#FFFFFF';
export const DISNEY_DARK_COLOR = '#444444';

export const Anchor = styled.a`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export const Container = styled.div<{ isDarkMode: boolean; isDisneyFooter?: boolean }>`
  font-family: 'Proxima Nova';
  font-size: 12px;

  ${({ isDarkMode, theme, isDisneyFooter }) =>
    isDisneyFooter
      ? `
        color: ${isDarkMode ? DISNEY_LIGHT_COLOR : DISNEY_DARK_COLOR};
      `
      : `
      color: ${isDarkMode ? theme.COLORS.WHITE : theme.COLORS.BLACK};
      `}

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${props => props.theme.SPACING.LG};
  padding-bottom: ${props => props.theme.SPACING.XL};
  margin-top: ${props => props.theme.SPACING.XL};

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    padding-top: ${props => props.theme.SPACING.MD};
    padding-bottom: ${props => props.theme.SPACING.LG};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    padding: ${props => `${props.theme.SPACING.XXS} ${props.theme.SPACING.SM}`};

    ${props => props.theme.MEDIA_QUERY.MOBILE} {
      padding: ${props => props.theme.SPACING.SM};
    }
  }
`;

export const ZolaSignature = styled.span`
  font-size: ${props => props.theme.FONTS.SIZE.SMALLER};
  margin-bottom: ${props => props.theme.SPACING.SM};
`;

export const StyledZolaLogo = styled(ZolaLogo)`
  width: 120px;
  margin-bottom: ${props => props.theme.SPACING.MD};
`;
