import React from 'react';

import FooterLink from 'components/publicWebsiteV2/common/Footer/components/FooterLink';

import COLORS from '@zola/zola-ui/src/styles/emotion/colors';
import {
  StyledZolaLogo,
  Row,
  ZolaSignature,
} from 'components/publicWebsiteV2/common/Footer/Footer.styles';

const FooterContent = ({ isDarkMode }: { isDarkMode?: boolean }): JSX.Element => (
  <>
    <StyledZolaLogo type="logomark" color={isDarkMode ? COLORS.CLOUD : COLORS.MARINE_BLUE} />
    <ZolaSignature>For all the days along the way</ZolaSignature>
    <Row>
      <FooterLink href="/about">About Zola</FooterLink>
      <FooterLink href="https://help.zola.com/hc/categories/115000284811-For-Wedding-Guests">
        Guest FAQs
      </FooterLink>
      <FooterLink href="/order-status">Order Status</FooterLink>
      <FooterLink href="mailto:support@zola.com">support@zola.com</FooterLink>
      <FooterLink href="tel:4086579652">1 (408) 657-ZOLA</FooterLink>
    </Row>
    <Row>
      <span>&copy; {new Date().getFullYear()} Zola, Inc. All rights reserved. </span>
      <span>
        <FooterLink href="/webaccessibility">Accessibility</FooterLink> /{' '}
        <FooterLink href="/privacy#CA-privacy-rights">Privacy</FooterLink> /{' '}
        <FooterLink href="/terms">Terms</FooterLink>
      </span>
    </Row>
  </>
);
export default FooterContent;
